/* Smart grid */
@import 'smart-grid.scss';

/* Fonts */
@import 'fonts.scss';

/* Variables */
@import 'variables.scss';

* {
    font-family: $ff-regular;
    box-sizing: border-box;
}

html,
body {
    background-color: $body-bg;
    font-family: $ff-regular;
    color: #fff;
    box-sizing: border-box;
}

.d-flex {
    display: flex;
}

.row {
    @include row-flex()
}

.col {
    @include col()
}

.col-1 {
    @include size(1)
}

.col-2 {
    @include size(2)
}

.col-3 {
    @include size(3)
}

.col-4 {
    @include size(4)
}

.col-5 {
    @include size(5)
}

.col-6 {
    @include size(6)
}

.col-7 {
    @include size(7)
}

.col-8 {
    @include size(8)
}

.col-9 {
    @include size(9)
}

.col-10 {
    @include size(10)
}

.col-11 {
    @include size(11)
}

.col-12 {
    @include size(12)
}

.shift-1 {
    @include shift(1)
}

.shift-2 {
    @include shift(2)
}

.shift-3 {
    @include shift(3)
}

.shift-4 {
    @include shift(4)
}

.shift-5 {
    @include shift(5)
}

.shift-6 {
    @include shift(6)
}

.h2 {
    font-size: 25px;
}

.text-align-center {
    text-align: center;
}

.my-2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mt-1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-3 {
    margin-top: 30px;
}

.mt-4 {
    margin-top: 40px;
}

.mw-100 {
    min-width: 100%;
}

.text-center {
    text-align: center;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.pl-0 {
    padding-left: 0;
}
