@font-face {
    font-family: "Lato regular";
    src: url("./../fonts/latoregular.woff2") format("woff2"),
    url("./../fonts/latoregular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato light";
    src: url("./../fonts/latolight.woff2") format("woff2"),
    url("./../fonts/latolight.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato bold";
    src: url("./../fonts/latobold.woff2") format("woff2"),
    url("./../fonts/latobold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
